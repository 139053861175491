<template>
  <div>
    <img src="../assets/1.拓扑图.png"/><br/><br/>
    <iframe class="excel" :src="'https://view.officeapps.live.com/op/view.aspx?src=http://nfsmid7.znglzx.cn/filedown/csff/list.xlsx'" frameborder="0"></iframe> 
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
img{
  width: 60%;
  height: 50%;
}
.excel{
  width: 1400px;
  height: 800px;
}
</style>